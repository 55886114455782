import { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { TagCloud } from "@frank-mayer/react-tag-cloud";
import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import ProjectsSection from "./ProjectsSection";
import EmailSection from "./EmailSection";
// import TagCloudComp from "./Layout/TagCloudComp";
import { TagCloudOptions } from "TagCloud";

const Home = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  const variants = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
  };
  return (
    <div className="container mx-auto mt-24 px-12 py-3">
      <HeroSection />
      <AboutSection />
      <ProjectsSection />
      <motion.div
        ref={ref}
        variants={variants}
        initial="initial"
        animate={isInView ? "animate" : "initial"}
        transition={{ duration: 0.5 }}
        className="flex justify-center text-primary-400 pt-12 md:pt-24"
      >
        {/*<TagCloudComp />*/}
        <TagCloud
          options={(w: Window & typeof globalThis): TagCloudOptions => ({
            radius: Math.min(500, w.innerWidth, w.innerHeight) / 2,
            maxSpeed: "fast",
          })}
          onClick={(tag: string, ev: MouseEvent) =>
            window.open(`https://www.google.com/search?q=${tag}`, "_blank")
          }
          onClickOptions={{ passive: true }}
          className="cursor-pointer z-0"
        >
          {[
            "HTML5",
            "CSS3",
            "JavaScript",
            "TypeScript",
            "Angular",
            "React.js",
            "Redux",
            "MongoDB",
            "Next.js",
            "Github",
            "Git",
            "Linux",
            "Python",
            "Django",
            "Flask",
            "Bootstrap",
            "Dart",
            "Flutter",
            "WebStorm",
            "VS Code",
          ]}
        </TagCloud>
      </motion.div>
      <EmailSection />
    </div>
  );
};

export default Home;
