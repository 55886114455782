import React from "react";
import { CodeBracketIcon, EyeIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import "../../styles/ProjectCard.css";

const ProjectCard: React.FC<{
  imgUrl: string;
  title: string;
  description: string;
  gitUrl?: string;
  preview?: string;
  technologies?: string[];
}> = ({ imgUrl, title, description, gitUrl, preview, technologies }) => {
  return (
    <div className="bg-[#181818] h-full">
      <div
        className="h-52 md:h-72 rounded-t-xl relative group"
        style={{
          background: `url(${imgUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className={`overlay projectCard`}>
          {gitUrl ? (
            <Link to={gitUrl} target="_blank" className={`links group/link`}>
              <CodeBracketIcon className="icons" />
            </Link>
          ) : null}
          {preview ? (
            <Link to={preview} target="_blank" className="links">
              <EyeIcon className="icons" />
            </Link>
          ) : null}
        </div>
      </div>
      <div className="h-[calc(100%-13rem)] md:h-[calc(100%-18rem)] text-white mt-2 px-2 pb-1 rounded-b-xl bg-[#181818] flex flex-col justify-between">
        <div>
          <h5 className="font-xl font-semibold mb-2">{title}</h5>
          <p className="text-[#ADB7BE]">{description}</p>
        </div>
        <div>
          <hr className="border-t border-[#ADB7BE] my-3" />
          <ul className="text-[#ADB7BE] flex flex-wrap gap-x-2 font-mono">
            {technologies?.map((t, index) => (
              <li key={index}>{t}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
