import { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import ProjectCard from "./Layout/ProjectCard";
import ProjectTag from "./Layout/ProjectTag";
import first from "../assets/images/projects/1.jpg";
import second from "../assets/images/projects/2.png";

const projectsTags = ["All", "Web", "Mobile"];

const projectsData = [
  {
    id: 1,
    title: "myChauffeurservice.de",
    description: `A website for the presentation of a limousine chauffeur service, with the possibility to make online bookings,
    convenient online payment with the Paypal, make callback appointments and much more.`,
    image: first,
    tag: ["All", "Web"],
    gitUrl: "https://github.com/MustafaGuer",
    previewUrl: "https://mychauffeurservice.de/",
    technologies: [
      "NextJS",
      "Redux",
      "Tailwind",
      "Bootstrap",
      "Google Maps",
      "Paypal",
      "Nodemailer",
    ],
  },
  {
    id: 2,
    title: "acg-webdevelopment.de",
    description: "Landing page for a Web Development Company",
    image: second,
    tag: ["All", "Web"],
    gitUrl: "https://github.com/MustafaGuer",
    previewUrl: "https://acg-webdevelopment.de",
    technologies: [
      "NextJS",
      "Redux",
      "Bootstrap",
      "SASS",
      "Nodemailer",
      "Nodemailer",
    ],
  },
];

const ProjectsSection = () => {
  const [currentTag, setCurrentTag] = useState("All");
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleTagChange = (newTag: string) => {
    setCurrentTag(newTag);
  };

  const filteredProjects = projectsData.filter((project) =>
    project.tag.includes(currentTag)
  );

  const cardVariants = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
  };

  return (
    <section id="projects">
      <h2 className="text-center text-4xl font-bold text-white mb-2">
        My projects
      </h2>
      <div className="text-white flex flex-row justify-center items-center gap-2 py-6">
        {projectsTags.map((tag, index) => (
          <ProjectTag
            key={index}
            name={tag}
            onClick={handleTagChange}
            isSelected={currentTag === tag}
          />
        ))}
      </div>
      <ul ref={ref} className="grid md:grid-cols-3 gap-8 md:gap-12">
        {filteredProjects.map((project, index) => (
          <motion.li
            key={project.id}
            variants={cardVariants}
            initial="initial"
            animate={isInView ? "animate" : "initial"}
            transition={{ duration: 0.3, delay: index * 0.4 }}
          >
            <ProjectCard
              imgUrl={project.image}
              title={project.title}
              description={project.description}
              gitUrl={project.gitUrl}
              preview={project.previewUrl}
              technologies={project.technologies}
            />
          </motion.li>
        ))}
      </ul>
    </section>
  );
};

export default ProjectsSection;
