import React from "react";
import {Link} from 'react-scroll';
import {ILink} from '../../interfaces/ILink';

const MenuOverlay: React.FC<{ links: ILink[], onCloseMenu: () => void }> = ({links, onCloseMenu}) => {
  return (
    <>
      <ul className="flex flex-col py-4 items-center">
        {links.map((link, index) => (
          <li key={index}>
            <Link
              activeClass="border-b border-[#ADB7BE] text-white"
              offset={-100}
              to={link.path}
              spy={true}
              className="block py-2 pl-3 pr-4 text-[#ADB7BE] sm-text-xl  md:p-0 hover:text-white"
              onClick={onCloseMenu}
            >
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MenuOverlay;