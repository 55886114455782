import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

const ImprintPage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="container mx-auto px-12 text-white mt-24 flex flex-col gap-4 h-[calc(100vh-250px)]">
        <h1 className="text-3xl">Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Mustafa G&uuml;r
          <br />
          Werinherstra&szlig;e 12
          <br />
          81541 M&uuml;nchen
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 (0) 176 24 89 99531
          <br />
          E-Mail:{" "}
          <Link
            to="mailto:mustafaguer@outlook.com"
            className="underline decoration-white"
          >
            mustafaguer@outlook.com
          </Link>
        </p>

        <p>
          Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
        </p>
      </section>
    </>
  );
};

export default ImprintPage;
