import { Link } from "react-router-dom";
import MainHeader from "../components/Layout/MainHeader";
import Footer from "../components/Layout/Footer";

const ErrorPage = () => {
  return (
    <>
      <MainHeader />
      <section className="container mx-auto px-12 text-white mt-24 flex flex-col gap-4 h-[calc(100vh-250px)]">
        <h1 className="text-3xl">An Error Occured!</h1>
        <p>Could not find thid page!</p>
        <Link to="/" className="underline">Back to Homepage</Link>
      </section>
      <Footer />
    </>
  );
};

export default ErrorPage;
