import MainHeader from "./MainHeader";
import SideBars from "./SideBars";
import Footer from "./Footer";
import {Outlet} from "react-router-dom";

const RootLayout = () => {
  return (
    <>
      <MainHeader />
      <main className="min-h-screen bg-[#121212] relative">
        <SideBars />
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default RootLayout;