import {Link} from 'react-router-dom';
import github from '../../assets/icons/github-icon.svg';
import linkedin from '../../assets/icons/linkedin-icon.svg';
import arrow from '../../assets/icons/arrowDown.svg';
import '../../styles/SideBars.css'

const SideBars = () => {
  return (
    <>
      <div
        className="sidebars leftSidebar left-2.5"
      >
        <div>
          <Link to="https://github.com/MustafaGuer" target="_blank" rel="noreferrer noopener">
            <img src={github} alt="Link to Github Profile" width={30} />
          </Link>
          <Link to="https://www.linkedin.com/in/mustafa-gür" target="_blank" rel="noreferrer noopener">
            <img src={linkedin} alt="Link to LinkedIn Profile" width={30} />
          </Link>
          <div className="h-28 mx-0 my-6" />
        </div>
      </div>
      <div className="sidebars rightSidebar right-2.5">
        <div>
          <Link to="mailto:mustafaguer@outlook.com">
            mustafaguer@outlook.com
          </Link>
          <div className="h-20 mt-3.5" />
          <span className="leading-8">scroll down</span>
          <div className="h-12 mt-1" />
          <img className="-mt-3.5" src={arrow} alt="Arrow Down" width={33} />
        </div>
      </div>
    </>
  );
};

export default SideBars;