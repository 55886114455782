import React from "react";
import {motion} from 'framer-motion';

const variants = {
  default: {width: 0},
  active: {width: "calc(100% - 0.75rem"}
}

const TabButton: React.FC<{
  active: boolean;
  selectedTab: () => void;
  children: React.ReactNode;
}> = ({active, selectedTab, children}) => {
  const btnCss = active ? "text-white" : "text-[#ADB7BE]";

  return (
    <button onClick={selectedTab}>
      <p className={`mr-3 font-semibold hover:text-white ${btnCss}`}>
        {children}
      </p>
      <motion.div
        variants={variants}
        animate={active ? "active" : "default"}
        className="h-1 bg-primary-500 mt-2 mr-3"
      ></motion.div>
    </button>
  );
};

export default TabButton;