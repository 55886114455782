import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";

import { ILink } from "../../interfaces/ILink";
import MenuOverlay from "./MenuOverlay";

const navLinks: ILink[] = [
  { title: "About", path: "about" },
  { title: "Projects", path: "projects" },
  { title: "Contact", path: "contact" },
];

const MainHeader = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const location = useLocation();

  const closeMenu = () => {
    setNavbarOpen(false);
  };

  return (
    <header>
      <nav className="fixed top-0 left-0 right-0 z-10 bg-[#121212] bg-opacity-100 border-[#33353F] border-b">
        <div className="flex container flex-wrap lg:py-4 items-center justify-between mx-auto px-4 py-2">
          <Link
            to="/"
            className="text-white text-2xl md:text-3xl font-semibold font-mono"
          >
            MG
          </Link>
          <div className="mobile-menu block md:hidden">
            {!navbarOpen ? (
              <button
                onClick={() => setNavbarOpen(true)}
                className="flex items-center px-3 py-2 border rounded border-slate-200 text-slate-200 hover:text-white hover:border-white"
              >
                <Bars3Icon className="h-5 w-5" />
              </button>
            ) : (
              <button
                onClick={() => setNavbarOpen(false)}
                className="flex items-center px-3 py-2 border rounded border-slate-200 text-slate-200 hover:text-white hover:border-white"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className="menu hidden md:block md:w-auto" id="navbar">
            <ul className="flex p-4 md:p-0 md:flex-row md:space-x-8 mt-0">
              {location.pathname === "/"
                ? navLinks.map((link, index) => (
                    <li key={index}>
                      <ScrollLink
                        activeClass="border-b border-[#ADB7BE] text-white"
                        offset={-100}
                        to={link.path}
                        spy={false}
                        className="block py-2 pl-3 pr-4 text-[#ADB7BE] sm-text-xl  md:p-0 hover:text-white cursor-pointer"
                      >
                        {link.title}
                      </ScrollLink>
                    </li>
                  ))
                : navLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={`/`}
                        className="block py-2 pl-3 pr-4 text-[#ADB7BE] sm-text-xl  md:p-0 hover:text-white"
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        {navbarOpen ? (
          <MenuOverlay links={navLinks} onCloseMenu={closeMenu} />
        ) : null}
      </nav>
    </header>
  );
};

export default MainHeader;
